.home{
    position: relative;
    min-height: 100vh;
    display: flex ;
    align-items: center;
    justify-content: center;
}

.intro{
    max-width: 540px;
    text-align: center;
    z-index: 1;
}

.home_img {
    margin-bottom: 1.5rem;
    border-radius: 50%;
    width: 50%;
    height: 50%;
}

.home-name{
    font-size: var(--h1-font-size);
    font-weight: var(--font-bold);
    margin-bottom: 0.5rem;
}

.home_socials {
    display: flex;
    column-gap: 1.75rem;
    margin: 1.5rem 0;
    align-items: center;
    justify-content: center;
}

.home_socials-link{
    color: var(--title-color);
    font-size: 1.3rem;
    transition: .3s;
}

.home_socials-link:hover{
    color: hsl(43, 100%, 68%);
}

.scroll_down {
    position: absolute;
    bottom: 2.5rem;
    left: 0;
    width: 100%;
}

.home_scroll-name{ 
    font-size: var(--small-font-size);
}

.mouse{
    border: 2px solid #454360;
    display: block;
    height: 1.6rem;
    width: 1.25rem;
    margin: auto;
    margin-top: .75rem;
    border-radius: 1rem;
    position: relative;
}

@keyframes ani-mouse{
    0%{
        top: 29%;
    }
    15%{
        top: 50%;
    }
    50%{
        top: 50%;
    }
    100%{
        top: 29%;
    }
    
}
.wheel{
    background-color: var(--title-color);
    border-radius: 100%;
    width: 0.25rem;
    height: 0.25rem;
    position: absolute;
    top: .5rem;
    left: 50%;
    transform: translate(-50%);
    animation: ani-mouse 2s linear infinite;
}

.shapes{
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
}

.shape {
    position: absolute;
}

.s1{
    left: 2%;
    top: 10%;
}

.s2{
    left: 18%;
    top: 30%;
}

.s3{
    left: 5%;
    bottom: 30%;
}

.s4{
    left: 2%;
    bottom: 10%;
}

.s5{
    left: 44%;
    top: 10%;
}

.s6{
    left: 36%;
    bottom: 10%;
}

.s7{
    right: 25%;
    top: 20%;
}

.s8{
    right: 24%;
    bottom: 20%;
    color: #a60d4f;
}

.s9{
    right: 2%;
    top: 10%;
    color: #e3ef41;
}

.s10{
    right: 11%;
    top: 45%;
    color: #0da62c;
}

.s11{
    right: 2%;
    bottom: 10%;
    color: #a60d4f;
}


@media screen and (max-width : 1024px) {
    .home_social-link {
        font-size: 1.125rem;
    }
  }