.aside {
    position: fixed;
    left: 0;
    top: 0;
    background: var(--body-color);
    border-right: 1px solid rgba(0, 0, 0, 0.05);
    padding: 2.5rem;
    width: 110px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 1000;
    transition: 0.3s;
}

.nav_list {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    padding-top: 2rem;
}

.nav_link {
    font-size: 1.5rem;
    color: var(--title-color);
    font-weight: var(--font-bold);
    transition: 0.3s;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nav_link::before {
    content: '';
    position: absolute;
    left: -5px;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 3px;
    background-color: var(--first-color);
    transition: width 0.3s;
}

.nav_link:hover::before,
.nav_link.active::before {
    width: 25px;
}

.nav_link:hover,
.nav_link.active {
    color: var(--first-color);
    transform: translateX(5px);
}

.nav_toggle {
    display: none;
}

@media screen and (max-width: 1024px) {
    .aside {
        left: -110px;
    }

    .nav_toggle {
        display: flex;
        position: fixed;
        top: 1.25rem;
        left: 1.25rem;
        cursor: pointer;
        height: 40px;
        width: 40px;
        background: var(--container-color);
        border-radius: 50%;
        padding: 0.5rem;
        justify-content: center;
        align-items: center;
        z-index: 1001;
        transition: 0.3s;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    }

    .nav_toggle:hover {
        background: var(--first-color);
        color: white;
    }

    .show-menu {
        left: 0;
    }
}

@media screen and (max-width: 768px) {
    .aside {
        width: 80px;
        padding: 1rem 0.5rem;
        background: var(--container-color);
        box-shadow: 2px 0 10px rgba(0, 0, 0, 0.1);
    }

    .nav_list {
        row-gap: 1.5rem;
    }

    .nav_link {
        font-size: 1.2rem;
        padding: 0.5rem;
        border-radius: 8px;
        transition: all 0.3s ease;
    }

    .nav_link:hover {
        background: var(--first-color);
        color: white;
    }

    .nav_link::before {
        display: none;
    }

    .copyright {
        display: none;
    }
}

@media screen and (max-width: 480px) {
    .aside {
        width: 70px;
    }

    .nav_toggle {
        height: 35px;
        width: 35px;
        top: 1rem;
        left: 1rem;
    }

    .nav_link {
        font-size: 1.1rem;
    }
}