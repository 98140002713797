.toggl {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 2px solid var(--first-color);
    border-radius: 1.5rem;
    position: fixed;
    top: 1rem;
    right: 1rem;
    padding: 0.3rem;
    cursor: pointer;
    width: 3.5rem;
    height: 1.75rem;
    transition: all 0.3s ease;
    background: var(--container-color);
    z-index: 1000;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.toggl i {
    font-size: 1rem;
    color: var(--first-color);
    z-index: 1;
}

.t_button {
    border-radius: 50%;
    background-color: var(--first-color);
    position: absolute;
    width: 1.2rem;
    height: 1.2rem;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.3s ease;
}

.toggl:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
}

.toggl:active {
    transform: scale(0.95);
}

@media screen and (max-width: 768px) {
    .toggl {
        width: 3rem;
        height: 1.5rem;
        top: 0.75rem;
        right: 0.75rem;
    }

    .toggl i {
        font-size: 0.9rem;
    }

    .t_button {
        width: 1rem;
        height: 1rem;
    }
}

@media screen and (max-width: 480px) {
    .toggl {
        width: 2.5rem;
        height: 1.25rem;
        border-width: 1.5px;
    }

    .toggl i {
        font-size: 0.8rem;
    }

    .t_button {
        width: 0.9rem;
        height: 0.9rem;
    }
}