.blog_container {
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
}
.blog{
    height: 100%;
}
.blog_card {
    background: var(--container-color);
    border-radius: var(--border-radius);
    overflow: hidden;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.blog_card:hover {
    transform: translateY(-10px);
}

.blog_thumb {
    position: relative;
    overflow: hidden;
    height: 220px;
}

.blog_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.blog_thumb:hover .blog_img {
    transform: scale(1.1);
}

.blog_category {
    position: absolute;
    top: 1rem;
    left: 1rem;
    background: var(--first-color);
    color: #fff;
    padding: 0.5rem 1rem;
    border-radius: 2rem;
    font-size: 0.875rem;
    z-index: 1;
}

.blog_details {
    padding: 1.5rem;
}

.blog_title {
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
    color: var(--title-color);
}

.blog_meta {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.875rem;
    color: var(--text-color);
}

.blog_description {
    margin-top: 1rem;
    font-size: 0.9rem;
    line-height: 1.6;
    color: var(--text-color);
}

.blog_link {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--first-color);
    font-weight: 500;
    margin-top: 1rem;
}

.blog_link:hover {
    text-decoration: underline;
}

@media screen and (max-width: 1024px) {
    .blog_container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 768px) {
    .blog_container {
        grid-template-columns: 1fr;
        padding: 0 1rem;
    }

    .blog_thumb {
        height: 200px;
    }
}

@media screen and (max-width: 480px) {
    .blog_thumb {
        height: 180px;
    }
    
    .blog_details {
        padding: 1rem;
    }

    .blog_title {
        font-size: 1.1rem;
    }

    .blog_description {
        font-size: 0.85rem;
    }
}