.services_container {
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
}

.services{
  height: 100%;
}

.services_card {
  text-align: center;
  padding: 3rem 2rem;
  border-radius: var(--border-radius);
  background-color: var(--container-color);
  transition: all 0.3s ease-in-out;
  box-shadow: var(--shadow);
}

.services-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin: 0 auto;
  text-align: center;
}

.services-section h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.services-section p {
  font-size: 1rem;
  margin: 0.5rem 0;
  line-height: 1.5;
}

.services-section a {
  color: #007bff; /* Lien bleu */
  text-decoration: none;
}

.services-section a:hover {
  text-decoration: underline;
}

.services_img {
  width: 90px;
  height: 90px;
  margin-bottom: 1.5rem;
}

.services_title {
  font-size: var(--h3-font-size);
  margin-bottom: 1rem;
  color: var(--title-color);
}

.services_description {
  color: var(--text-color);
}

.services_card:nth-child(1) {
  background: linear-gradient(145deg, #000000, #000000);
}

.services_card:nth-child(2) {
  background: linear-gradient(145deg, #030303, #12f2c5);
}

.services_card:nth-child(3) {
  background: linear-gradient(145deg, #12f2c5, #12f2c5);
}

.services_card:nth-child(1) .services_title,
.services_card:nth-child(1) .services_description,
.services_card:nth-child(2) .services_title,
.services_card:nth-child(2) .services_description,
.services_card:nth-child(3) .services_title,
.services_card:nth-child(3) .services_description {
  color: white;
}

@media screen and (max-width: 1024px) {
  .services_container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .services_container {
    grid-template-columns: 1fr;
  }

  .services_card {
    padding: 2rem 1.5rem;
  }
}


/* Responsive design pour petits écrans */
@media (max-width: 768px) {
  .services-section h2 {
    font-size: 1.5rem;
  }

  .services-section p {
    font-size: 0.9rem;
  }

  .services-section {
    padding: 15px;
  }
}