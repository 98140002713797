.resume_container {
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1.875rem;
}

.resume{
  height: 100%;
}

.timeline {
  background-color: var(--container-color);
  padding: 1.875rem;
  border-radius: var(--border-radius);
  position: relative;
  box-shadow: var(--shadow);
  margin: 0.5rem;
  transition: transform 0.3s ease;
}

.timeline:hover {
  transform: translateY(-5px);
}

.timeline_item {
  position: relative;
  padding-left: 3.125rem;
  padding-bottom: 3.125rem;
}

.timeline_item:last-child {
  padding-bottom: 0;
}

.timeline_item::before {
  content: '';
  width: 2px;
  height: 100%;
  background: linear-gradient(
    var(--first-color),
    rgba(255, 76, 96, 0.3)
  );
  position: absolute;
  left: 0.25rem;
  top: 0;
}

.timeline .icon-briefcase,
.timeline .icon-graduation {
  position: absolute;
  left: -0.4375rem;
  top: 0;
  font-size: var(--h2-font-size);
  color: var(--first-color);
  background-color: var(--container-color);
  padding: 0.4375rem;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.span_ex_fr {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--first-color);
  margin-bottom: 1.5rem;
  display: block;
  position: relative;
  padding-left: 1.5rem;
}

.span_ex_fr::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  width: 1rem;
  height: 2px;
  background-color: var(--first-color);
  transform: translateY(-50%);
}

.timeline_date {
  color: #8b88b1;
  font-size: var(--small-font-size);
  margin-bottom: 0.5rem;
}

.timeline_title {
  font-size: var(--h3-font-size);
  margin: 0.5rem 0;
  color: var(--title-color);
}

.timeline_text {
  font-size: var(--normal-font-size);
  line-height: 1.6;
}

@media screen and (max-width: 1024px) {
  .resume_container {
    grid-template-columns: 450px;
    justify-content: center;
    row-gap: 1.875rem;
  }

  .timeline_item::before {
    left: 2px;
  }
}

@media screen and (max-width: 576px) {
  .resume_container {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 350px) {
  .timeline_item {
    padding-left: 1.875rem;
    padding-bottom: 1.875rem;
  }

  .timeline_item::before {
    left: 0;
  }

  .timeline .icon-briefcase,
  .timeline .icon-graduation {
    left: -9px;
  }

  .timeline_text {
    text-align: justify;
  }
}