.portfolio_container {
    grid-template-columns: repeat(3, 1fr);
    gap: 1.875rem;
}

.portfolio{
    height: 100%;
}


.portfolio_card {
    position: relative;
    border-radius: var(--border-radius);
    box-shadow: var(--shadow);
    overflow: hidden;
    background: var(--container-color);
    transition: transform 0.3s ease;
}

.portfolio_card:hover {
    transform: translateY(-5px);
}

.portfolio_thumbnail {
    position: relative;
    overflow: hidden;
    height: 200px;
}

.portfolio_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.portfolio_card:hover .portfolio_img {
    transform: scale(1.1);
}

.portfolio_content {
    padding: 1.5rem;
}

.portfolio_category {
    display: inline-block;
    background-color: var(--first-color);
    color: #fff;
    padding: 0.25rem 0.75rem;
    border-radius: 1rem;
    font-size: var(--small-font-size);
    margin-bottom: 1rem;
}

.portfolio_title {
    font-size: var(--h3-font-size);
    margin-bottom: 0.75rem;
    color: var(--title-color);
}

.portfolio_description {
    font-size: var(--small-font-size);
    color: var(--text-color);
    margin-bottom: 1rem;
    line-height: 1.6;
}

.portfolio_tech {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-bottom: 1rem;
}

.tech_tag {
    background: rgba(255, 76, 96, 0.1);
    color: var(--first-color);
    padding: 0.25rem 0.5rem;
    border-radius: 0.5rem;
    font-size: var(--smaller-font-size);
}

.portfolio_links {
    display: flex;
    gap: 1rem;
}

.portfolio_link {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--title-color);
    font-size: var(--small-font-size);
    transition: color 0.3s ease;
}

.portfolio_link:hover {
    color: var(--first-color);
}

.portfolio_link i {
    font-size: 1.1rem;
}

@media screen and (max-width: 1024px) {
    .portfolio_container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 768px) {
    .portfolio_container {
        grid-template-columns: 1fr;
        padding: 0 1rem;
    }
}

@media screen and (max-width: 480px) {
    .portfolio_thumbnail {
        height: 180px;
    }

    .portfolio_content {
        padding: 1rem;
    }

    .portfolio_title {
        font-size: 1.1rem;
    }

    .portfolio_description {
        font-size: 0.85rem;
    }

    .tech_tag {
        font-size: 0.7rem;
    }
}